import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import { routes } from "../../constants/routes";
import { MastersProvider } from "../../context/MasterProider";
import CreateNewMaterial from "./Material/CreateNewMaterial";
import CreateNewSupplier from "./Supplier/CreateNewSupplier";
import ListMasters from "./ListMasters";
import CreateNewBom from "./BOM/CreateNewBom";
import File404 from "../../components/404/File404";

const MastersPages = ({ userContext }: any) => {
  console.log(userContext?.roleAccess, "userContext?.roleAccess");
  return (
    userContext?.roleAccess && (
      <MastersProvider>
        <Routes>
          <Route
            path={routes.mastersSupplier}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <ListMasters userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.createMastersSupplier}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewSupplier userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersEditSupplier}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewSupplier userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersViewSupplier}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewSupplier userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersMaterial}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <ListMasters userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.createMastersMaterial}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewMaterial userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersViewMaterial}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewMaterial userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersEditMaterial}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewMaterial userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.masters}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <ListMasters userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.listMasterBillBoardType}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <ListMasters userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.bomList}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <ListMasters userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.AddBomList}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewBom userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.editBomList}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewBom userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.viewBomList}
            element={
              <RequireAuth>
                {userContext?.roleAccess["MASTER"].includes("READ") ? (
                  <CreateNewBom userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
        </Routes>
      </MastersProvider>
    )
  );
};

export default MastersPages;
