import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "react-video-player-extended";
import * as Yup from "yup";
import AudioUpload from "../../components/Audiofile/AudioUpload";
import CommonButton from "../../components/CommonButton/CommonButton";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import CustomLocationSelect from "../../components/CommonComponents/CustomLocationSelect";
import ReactCustomSelect from "../../components/CommonComponents/ReactCustomSelect";
import FormikDateField from "../../components/FormikDateField/FormikDateField";
import FormikField from "../../components/FormikField/FormikField";
import CustomInputField from "../../components/InputField/CustomInputField";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import ImagePopUp from "../../components/PopupToast/ImagePopUp";
import Modal from "../../components/PopupToast/Modal";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import { routes } from "../../constants/routes";
import { updateText } from "../../utils/api";

import camera from "../../assets/camera.png";
import mic from "../../assets/mic.png";
import video from "../../assets/video.png";
import x from "../../assets/xo.png";
import { useChatContext } from "../livikit/context/ChatProvider";

let timedPopupSetTimeOut: any = undefined;

const CreateNewTicketForm = ({ context, userContext }: any) => {
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [buttonPopup, setButtonPopup] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState<any>({});
  const [deleteAttachmentVideo, setDeleteAttachmentVideo] = useState(false);
  const [currentAttachmentVideo, setCurrentAttachmentVideo] = useState<any>({});
  const [currentAttachmentSubmittedVideo, setCurrentAttachmentSubmittedVideo] =
    useState<any>({});
  const [bodyMessage, setBodyMessage] = useState("");
  const [timedPopup, setTimedPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentBillBoard, setCurrentBillBoard] = useState("");
  const [deleteAttachmentAudio, setDeleteAttachmentAudio] =
    useState<any>(false);
  const [currentAttachmentAudio, setCurrentAttachmentAudio] = useState<any>({});
  const [headerMessage, setHeaderMessage] = useState<any>("");
  const [imageShow, setImageShow] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [textMessage, setTextMessage] = useState<any>("");
  const [conformationPopUp, setConformationPopUp] = useState(false);
  const { t, i18n } = useTranslation();
  const [billBoardList, setBillBoardList] = useState<any>([]);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [ticketTitleList, setTicketTitleList] = useState<any>([]);
  const [departMentList, setDepartMentList] = useState<any>([]);
  const [departMentDetail, setDepartMentDetail] = useState<any>("");
  const [loading, setLoading] = useState(false);

  const priority = [
    { value: "HIGH", label: `${t("HIGH")}` },
    { value: "MEDIUM", label: `${t("MEDIUM")}` },
    { value: "LOW", label: `${t("LOW")}` },
  ];

  useEffect(() => {
    getTicketTitleDetail();
    getDepartmentDetail();
  }, []);

  useEffect(() => {
    getBillBoardDetails();
  }, [urlParams?.ticketId]);

  const getBillBoardDetails = async () => {
    const { success, billboards } = await context?.loadBillBoards({
      status: "ACTIVE",
      sort: "board_no",
    });
    if (success) {
      let res = billboards.map((d: any) => {
        return {
          label: d.board_no,
          value: d.id,
        };
      });
      setBillBoardList(res);
    }
    return { success, billboards };
  };

  const getTicketTitleDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "TICKET_TITLE",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setTicketTitleList(res);
    }
  };

  const getDepartmentDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "DEPARTMENT",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setDepartMentList(res);
    }
  };

  const getCurrentBillBoard = async () => {
    if (currentBillBoard) {
      await context?.getBillBoardDetail(currentBillBoard);
    }
  };

  const getUserDetails = async () => {
    const { success, user } = await context?.loadMembers({
      status: "ACTIVE",
      department: departMentDetail,
      sort: "name",
      role: "TECHNICIAN",
    });
    if (success) {
      if (formik?.initialValues?.department !== formik?.values?.department) {
        formik?.setErrors({ assignee: "Assignee is required" });
        formik?.setFieldValue("assignee", "");
        formik?.setFieldValue("assigneeName", "");
      }
      let res = user.map((d: any) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setAssigneeList(res);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [departMentDetail]);

  useEffect(() => {
    getCurrentBillBoard();
  }, [currentBillBoard]);

  const getTicketDetail = async () => {
    if (urlParams?.ticketId !== "0" && !context?.ticket?.id) {
      const { success, ticket } = await context?.getTicketDetail(
        urlParams.ticketId
      );
      if (success) {
        setDepartMentDetail(ticket?.department);
      }
    }
  };

  useEffect(() => {
    setCurrentBillBoard(context?.ticket?.bill_board_id?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context?.ticket?.pictures,
    context?.ticket?.videos,
    context?.ticket?.audios,
    urlParams?.ticketId,
  ]);

  useEffect(() => {
    if (urlParams?.ticketId) {
      getTicketDetail();
    } else {
      context?.removeTicket(null);
    }
  });

  const handleUpdateData = async (values: any) => {
    setImageShow(false);
    values = {
      ...values,
      title: values?.title,
      bill_board_id: values?.bill_board_id
        ? values?.bill_board_id
        : context?.billBoard.id,
      // audios: audioFile,
      start_date: moment(values?.start_date).format("yyyy-MM-DD"),
      end_date: moment(values?.end_date).format("yyyy-MM-DD"),
    };
    const { success, error } = urlParams.ticketId
      ? await context.updateTicketDetail(urlParams.ticketId, values)
      : await context.createTicketDetail(values);
    setIsSuccess(success);
    if (success) {
      formik.resetForm();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(
        urlParams.ticketId
          ? `${t("successError.Update the ticket")}`
          : `${t("successError.create the ticket successfully")}`
      );
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        formik.resetForm();
        setBodyMessage("");
        setTimedPopup(false);
        if (
          headerMessage !== t("successError.cancel the changes") &&
          !location.pathname.includes("/billboards") &&
          location.pathname.includes("/tickets")
        ) {
          navigate(routes.listTicketsUrl(context?.ticketQueryParamsDetails()));
        } else if (
          headerMessage !== t("successError.cancel the changes") &&
          location.pathname.includes("/billboards") &&
          location.pathname.includes("/tickets") &&
          urlParams?.ticketId
        ) {
          navigate(
            routes.getBillBoardTicketsActivityFeedUrl(
              urlParams?.billboardId,
              urlParams?.ticketId
            )
          );
        } else if (
          headerMessage !== t("successError.cancel the changes") &&
          location.pathname.includes("/billboards") &&
          location.pathname.includes("/tickets") &&
          !urlParams?.ticketId
        ) {
          navigate(routes.getBillBoardTicketsIdUrl(urlParams?.billboardId, 0));
        } else {
          setTimedPopup(false);
        }
      } else {
        setBodyMessage("");
        setTimedPopup(false);
      }
    }, 2000);
  };

  const cancelForm = () => {
    formik?.resetForm();
    setConformationPopUp(false);
  };

  const uploadImage = async (file: any) => {
    setLoading(true);
    let imageArray = [...formik?.values?.pictures];
    if (file?.length > 0) {
      for (let i = 0; i < file.length; i++) {
        const formData = new FormData();
        formData.append("file", file[i]);
        const data = await userContext?.uploadFiles(formData, "image");
        if (data?.success) {
          imageArray.push(data?.url);
        }
      }
      formik?.setFieldValue("pictures", [...imageArray]);
    }
    setLoading(false);
  };

  const getVideoUpload = async (file: any) => {
    setLoading(true);
    let videoArray = [...formik?.values?.videos];
    if (file?.length) {
      for (let i = 0; i < file.length; i++) {
        const formData = new FormData();
        formData.append("file", file[i]);
        const { success, url } = await userContext?.uploadFiles(
          formData,
          "video"
        );
        if (success) {
          videoArray.push(url);
        }
      }
      formik?.setFieldValue("videos", [...videoArray]);
    }
    setLoading(false);
  };

  const deleteImage = () => {
    setDeleteAttachment(false);
    setTimedPopup(true);
    setImageShow(false);
    setBodyMessage(`${t("successError.Image deleted successfully")}`);
    setIsSuccess(true);
    timeOutPopUp(false);
    let array = formik?.values?.pictures.filter(
      (item: any) => item !== currentAttachment?.file
    );
    formik?.setFieldValue("pictures", [...array]);
  };

  const deleteVideo = () => {
    setDeleteAttachmentVideo(false);
    setTimedPopup(true);
    setImageShow(false);
    setBodyMessage(`${t("successError.Video deleted successfully")}`);
    setIsSuccess(true);
    timeOutPopUp(false);
    let array = formik?.values?.videos.filter(
      (item: any) => item !== currentAttachmentVideo?.file
    );
    formik?.setFieldValue("videos", [...array]);
  };
  const chatContext = useChatContext();

  const deleteAudio = () => {
    setDeleteAttachmentAudio(false);
    setTimedPopup(true);
    setImageShow(false);
    setBodyMessage(`${t("successError.Audio deleted successfully")}`);
    setIsSuccess(true);
    timeOutPopUp(false);
    let array = formik?.values?.audios.filter(
      (item: any) => item !== currentAttachmentAudio?.file
    );
    formik?.setFieldValue("audios", [...array]);
  };

  const initialValues = {
    bill_board_no: urlParams?.ticketId
      ? context?.ticket?.bill_board_id?.board_no
      : urlParams?.billboardId
      ? context?.billBoard?.board_no
      : "",
    bill_board_id: urlParams?.ticketId
      ? context?.ticket?.bill_board_id?.id
      : urlParams?.billboardId
      ? context?.billBoard?.id
      : "",
    alternate_location: urlParams?.ticketId
      ? context?.ticket?.alternate_location
      : "",
    title: urlParams?.ticketId ? context?.ticket?.title : "",
    department: urlParams?.ticketId ? context?.ticket?.department : "",
    priority: urlParams?.ticketId ? context?.ticket?.priority : "",
    start_date: urlParams?.ticketId
      ? moment(context?.ticket?.start_date).format("yyyy-MM-DD")
      : "",
    end_date: urlParams?.ticketId
      ? moment(context?.ticket?.end_date).format("yyyy-MM-DD")
      : "",
    description: urlParams?.ticketId ? context?.ticket?.description : "",
    assignee: urlParams?.ticketId ? context?.ticket?.assignee?.id : "",
    assigneeName: urlParams?.ticketId ? context?.ticket?.assignee?.name : "",
    pictures: urlParams?.ticketId ? context?.ticket?.pictures : [],
    videos: urlParams?.ticketId ? context?.ticket?.videos : [],
    audios: urlParams?.ticketId ? context?.ticket?.audios : [],
    submitted_reason: urlParams?.ticketId
      ? context?.ticket?.submitted_reason
      : "",
  };

  const validationSchema = Yup.object({
    bill_board_id: Yup.string().required("billboard is required"),
    title: Yup.string().required("Title is required"),
    department: Yup.string().required("Department is required"),
    priority: Yup.string().required("Priority is required"),
    start_date: urlParams?.ticketId
      ? Yup.date().required("Start Date is required")
      : Yup.date().required("Start Date is required"),
    end_date: urlParams?.ticketId
      ? Yup.date().required("End Date is required")
      : Yup.date().required("End Date is required"),
    description: Yup.string()
      .min(2, "Too Short!")
      .max(1000)
      .required("Description is required"),
    assignee: Yup.string().required("Assignee is required"),
    submitted_reason:
      urlParams?.ticketId && context?.ticket?.status === "SUBMITTED"
        ? Yup.string().required("Submited Reason is required")
        : Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {},
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty, setSubmitting } =
    formik;
  const reAssignTicket = async () => {
    const { success, error } = await context?.reassignTicket(
      urlParams?.ticketId,
      {
        assignee: formik.values.assignee,
        department: formik.values.department,
        end_date: formik.values.end_date,
      }
    );
    setIsSuccess(success);
    if (success) {
      setTimedPopup(true);
      setBodyMessage(`${t("successError.reassigned the ticket successfully")}`);
      timeOutPopUp(true);
    } else {
      setTimedPopup(true);
      setBodyMessage(error);
      timeOutPopUp(false);
    }
  };

  const approveTicket = async (value: any) => {
    const { success, error } = value
      ? await context?.approveBySupervisorTicket(
          urlParams?.ticketId,
          formik?.values?.submitted_reason
        )
      : await context?.approveByStaffTicket(urlParams?.ticketId);
    setIsSuccess(success);
    if (success) {
      setTimedPopup(true);
      timeOutPopUp(true);
      setBodyMessage(
        value
          ? `${t("successError.verified the ticket successfully")}`
          : `${t("successError.approved the ticket successfully")}`
      );
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const rejectTicket = async () => {
    const { success, error } = await context?.rejectTicket(urlParams?.ticketId);
    setIsSuccess(success);
    if (success) {
      setTimedPopup(true);
      timeOutPopUp(true);
      setBodyMessage(`${t("successError.rejected the ticket successfully")}`);
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  interface billboardAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getBillboardOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { billboards } = await context?.loadBillBoards({
      board_status: "ACTIVE",
      sort: "board_no",
      search: inputValue,
    });
    callback(
      billboards.map((d: any) => ({
        label: d?.board_no,
        value: d?.id,
      }))
    );
  };

  const getAssigneeOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { users } = await context?.autoCompleteApi({
      department: formik?.values?.department ? formik?.values?.department : "",
      name: inputValue,
      role: "TECHNICIAN",
    });
    callback(
      users.map((d: any) => ({
        label: d?.name,
        value: d?.id,
      }))
    );
  };

  const getTicketTitleOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "TICKET_TITLE",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const getDepartmentOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "DEPARTMENT",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const createGroupConversation = async () => {
    const res = await chatContext?.createGroup({
      ticket_id: urlParams?.ticketId,
      group_name: context?.ticket?.ticket_no,
      group_member: [context?.ticket?.assignee?._id],
    });
    if (res?.success) {
      navigate(`/chat/${res?.conversation._id}`);
      userContext?.setGroupConversationInfo(res?.data);
    }
  };
  const formatedName = (name: string) => {
    return name?.length > 15 ? name.substring(0, 15) + "...." : name;
  };

  return (
    <div>
      {!window.location.pathname.includes("/0/edit") && (
        <FormikProvider value={formik}>
          <Form validated={true} onSubmit={handleSubmit}>
            <Row>
              <Col className="formprofile mb-4">
                <Field name="bill_board_no">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("new_tickets.BillBoard_Number") + " *"}
                        error={errors["bill_board_no"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          isFieldDisabled={
                            !!urlParams?.billboardId || !!urlParams?.ticketId
                          }
                          className={"select-wrapper"}
                          isMulti={false}
                          values={{
                            label: formik?.values?.bill_board_no,
                            value: formik.values.bill_board_id,
                          }}
                          options={billBoardList}
                          cacheOptions
                          loadOptionValue={getBillboardOptions}
                          updateField={(option: any) => {
                            formik?.setFieldValue(
                              "bill_board_id",
                              option.value
                            );
                            formik?.setFieldValue(
                              "bill_board_no",
                              option.label
                            );
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={6} className="formprofile mb-4">
                <Field name="department">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("new_tickets.Department")}
                        error={errors["department"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isFieldDisabled={
                            urlParams?.ticketId
                              ? !(
                                  context?.ticket?.status === "CREATED" ||
                                  context?.ticket?.status === "VIEWED" ||
                                  context?.ticket?.status === "REASSIGNED"
                                )
                              : false
                          }
                          isMulti={false}
                          values={{
                            label: updateText(
                              formik?.values?.department?.replace("_", " ")
                            ),
                            value: formik?.values?.department,
                          }}
                          options={departMentList}
                          cacheOptions
                          loadOptionValue={getDepartmentOptions}
                          updateField={(option: any) => {
                            formik?.setFieldValue("department", option.value);
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={6} className="formprofile mb-4">
                <Field name="priority">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("new_tickets.Priority")}
                        error={errors["priority"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <ReactCustomSelect
                          isFieldDisabled={
                            urlParams?.ticketId
                              ? !(
                                  context?.ticket?.status === "CREATED" ||
                                  context?.ticket?.status === "VIEWED" ||
                                  context?.ticket?.status === "REASSIGNED"
                                )
                              : false
                          }
                          className={"select-wrapper"}
                          feildName={"priority"}
                          defaultOptions={{
                            label: `${t(formik?.values?.priority)}`,
                            value: formik?.values?.priority,
                          }}
                          isMulti={false}
                          updateFieldValue={(data: any) =>
                            formik?.setFieldValue("priority", data.value)
                          }
                          dataOptions={priority}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={6} className="formprofile mb-4">
                <Field name="title">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("new_tickets.Ticket_Title")}
                        error={errors["title"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isFieldDisabled={
                            urlParams?.ticketId
                              ? !(
                                  context?.ticket?.status === "CREATED" ||
                                  context?.ticket?.status === "VIEWED" ||
                                  context?.ticket?.status === "REASSIGNED"
                                )
                              : false
                          }
                          isMulti={false}
                          values={{
                            label: updateText(
                              formik?.values?.title?.replace("_", " ")
                            ),
                            value: formik?.values?.title,
                          }}
                          options={ticketTitleList}
                          cacheOptions
                          loadOptionValue={getTicketTitleOptions}
                          updateField={(option: any) => {
                            formik?.setFieldValue("title", option.value);
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={12} className="formprofile mb-4">
                <Field name="assignee">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("new_tickets.Assignee")}
                        error={errors["assignee"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isFieldDisabled={
                            urlParams?.ticketId
                              ? !(
                                  context?.ticket?.status === "CREATED" ||
                                  context?.ticket?.status === "VIEWED" ||
                                  context?.ticket?.status === "REASSIGNED"
                                )
                              : false
                          }
                          isMulti={false}
                          values={{
                            label: formik?.values?.assigneeName,
                            value: formik.values.assignee,
                          }}
                          options={assigneeList}
                          cacheOptions
                          loadOptionValue={getAssigneeOptions}
                          updateField={(option: any) => {
                            formik?.setErrors({});
                            formik?.setFieldValue("assignee", option.value);
                            formik?.setFieldValue("assigneeName", option.label);
                            formik?.setErrors({});
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={6} className="formprofile">
                <FormikDateField
                  name={"start_date"}
                  label={`${t("new_tickets.Start_Date")}*`}
                  isDisabled={
                    urlParams?.ticketId
                      ? !(
                          context?.ticket?.status === "CREATED" ||
                          context?.ticket?.status === "VIEWED" ||
                          context?.ticket?.status === "REASSIGNED"
                        )
                      : false
                  }
                />
              </Col>

              <Col md={6} className="formprofile mb-4">
                <FormikDateField
                  name={"end_date"}
                  label={`${t("new_tickets.End_Date")}*`}
                  minDate={formik.values.start_date}
                  isDisabled={
                    urlParams?.ticketId
                      ? !(
                          context?.ticket?.status === "CREATED" ||
                          context?.ticket?.status === "VIEWED" ||
                          context?.ticket?.status === "REASSIGNED" ||
                          context?.ticket?.status === "OVERDUE"
                        )
                      : false
                  }
                />
              </Col>

              <Col md={12} className="formprofile mb-4">
                <Field name="alternate_location">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("new_tickets.alternate_location")}
                        error={errors["alternate_location"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CustomLocationSelect
                          fiedName={"alternate_location"}
                          isFieldDisabled={
                            urlParams?.ticketId
                              ? !(
                                  context?.ticket?.status === "CREATED" ||
                                  context?.ticket?.status === "VIEWED" ||
                                  context?.ticket?.status === "REASSIGNED"
                                )
                              : false
                          }
                          className={"autolocation"}
                          updateField={(data: any) => {
                            formik.setFieldValue(
                              "alternate_location",
                              data?.formatted_address
                            );
                          }}
                          defaultValue={formik?.values?.alternate_location}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={12} className="mb-4">
                <FormikField
                  name="description"
                  type="text"
                  as="textarea"
                  validationSchema={validationSchema}
                  label={t("new_tickets.Description")}
                  errors={errors as Record<string, string>}
                  disabled={
                    urlParams?.ticketId
                      ? !(
                          context?.ticket?.status === "CREATED" ||
                          context?.ticket?.status === "VIEWED" ||
                          context?.ticket?.status === "REASSIGNED"
                        )
                      : false
                  }
                />
              </Col>

              {context?.ticket?.submitted_at?.length > 0 && (
                <Col md={12} className="mb-4">
                  <FormikField
                    name="submitted_reason"
                    type="text"
                    as="textarea"
                    validationSchema={validationSchema}
                    label={t("new_tickets.Submit Reason")}
                    errors={errors as Record<string, string>}
                    disabled={context?.ticket?.status !== "SUBMITTED"}
                  />
                </Col>
              )}
            </Row>

            <div className=" d-flex mt-4 flex-wrap ">
              {context?.ticket?.createdAt?.length > 0 && (
                <div
                  className=" px-4 border py-3  mt-3 me-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Created By"} </label>
                      <div>
                        {formatedName(context?.ticket?.created_by?.name)}
                      </div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Created On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.createdAt}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}
              {context?.ticket?.viewed_on?.length > 0 && (
                <div
                  className=" px-4 border py-3  mt-3 me-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Viewed By"} </label>
                      <div>{context?.ticket?.assignee?.name}</div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Viewed On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.viewed_on}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}
              {context?.ticket?.submitted_at?.length > 0 && (
                <div
                  className=" px-4 border py-3  mt-3 me-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Submitted By"} </label>
                      <div>{context?.ticket?.submitted_by?.name}</div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Submitted On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.submitted_at}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}

              {context?.ticket?.approved_on_supervisor?.length > 0 && (
                <div
                  className=" px-4 border py-3  me-3 mt-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Verified By"} </label>
                      <div>{context?.ticket?.approved_by_supervisor?.name}</div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Verified On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.approved_on_supervisor}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}
              {context?.ticket?.reassigned_on?.length > 0 && (
                <div
                  className=" px-4 border py-3  me-3 mt-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Reassigned By"} </label>
                      <div>{context?.ticket?.reassigned_by?.name || "-"}</div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Reassigned On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.reassigned_by?.confirmed_at}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}
              {context?.ticket?.rejected_at?.length > 0 && (
                <div
                  className=" px-4 border py-3  me-3 mt-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Rejected By"} </label>
                      <div>{context?.ticket?.rejected_by?.name || "-"}</div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Rejected On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.rejected_at}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}
              {context?.ticket?.approved_on?.length > 0 && (
                <div
                  className=" px-4 border py-3  me-3 mt-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Approved By"} </label>
                      <div>{context?.ticket?.approved_by_manager?.name}</div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Approved On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.approved_on}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}
              {context?.ticket?.status === "CLOSED" && (
                <div
                  className=" px-4 border py-3  me-3 mt-3"
                  style={{ width: "165px" }}
                >
                  <div>
                    <FormGroup>
                      <label>{"Closed By"} </label>
                      <div>
                        {context?.ticket?.approved_on
                          ? context?.ticket?.approved_by_manager?.name
                          : context?.ticket?.rejected_by?.name}
                      </div>
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className="mt-4">
                      <label>{"Closed On"} </label>
                      <div>
                        <Moment format="DD/MM/yyyy">
                          {context?.ticket?.approved_on
                            ? context?.ticket?.approved_on
                            : context?.ticket?.rejected_at}
                        </Moment>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              )}
            </div>
            {(urlParams?.ticketId
              ? context?.ticket?.status === "CREATED" ||
                context?.ticket?.status === "VIEWED" ||
                context?.ticket?.status === "REASSIGNED"
              : true) && (
              <div className="row mt-5">
                <div className="col-md-6 pe-md-4">
                  <div className=" d-flex d-block uploads align-items-center justify-content-center position-relative fileupload shadow-bg py-2 cursor-pointer">
                    <div>
                      <img src={camera} alt="" width={24} />
                    </div>
                    <input
                      type={"file"}
                      name="myfile"
                      multiple
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e: any) => uploadImage(e.target.files)}
                    />
                    <h6 className=" mb-0 mx-3 fw-semibold ">
                      {t("new_tickets.UPLOAD_PHOTOS")}
                    </h6>
                  </div>
                </div>
                <div
                  className={`col-md-6 ${
                    i18n.language === "en" ? "ps-md-5 " : "pe-md-5 "
                  }`}
                >
                  <div className=" d-flex d-block uploads align-items-center justify-content-center shadow-bg py-2 cursor-pointer">
                    <div
                      className=" d-flex align-items-center "
                      onClick={() => setButtonPopup(true)}
                    >
                      <h6 className=" m-0">{t("new_tickets.VOICE")}</h6>
                      <div className="iconforbillboardform ">
                        <img src={mic} alt="" width={24} />
                      </div>
                    </div>
                    <div>|</div>
                    <div className=" d-flex align-items-center position-relative videoupload overflow-hidden ">
                      <h6 className=" mx-3 mb-0">{t("new_tickets.VIDEO")}</h6>
                      <input
                        type={"file"}
                        name="myfile"
                        multiple
                        accept=".mp4,.mpeg,.avi"
                        className="inputfilevideo"
                        onChange={(e: any) => getVideoUpload(e.target.files)}
                      />
                      <div className="iconforbillboardform">
                        <img src={video} alt="" width={24} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(formik?.values?.pictures?.length > 0 ||
              formik?.values?.videos?.length > 0 ||
              formik?.values?.audios?.length > 0) && (
              <>
                <div
                  className={i18n.language === "en" ? "pt-5" : " pt-5 pe-md-4"}
                >
                  <label htmlFor="" className="attachline">
                    {t("new_tickets.Attachments")}
                  </label>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12 pe-md-4 ">
                    <div className=" d-flex py-5 flex-wrap gap-4">
                      {formik?.values?.pictures.map((img: any, index: any) => {
                        return (
                          <div className="attachment" key={index}>
                            <div className="attachment position-relative shadow-bg">
                              <img
                                src={img}
                                alt=""
                                className="embet"
                                onClick={() => {
                                  setImageShow(true);
                                  setCurrentImage(img);
                                }}
                              />
                              {(urlParams?.ticketId
                                ? context?.ticket?.status === "CREATED" ||
                                  context?.ticket?.status === "VIEWED" ||
                                  context?.ticket?.status === "REASSIGNED"
                                : true) && (
                                <img
                                  src={x}
                                  alt=""
                                  width={15}
                                  className=" position-absolute x_close"
                                  // onClick={() => deleteImage(img, index)}
                                  onClick={() => {
                                    setDeleteAttachment(true);
                                    setCurrentAttachment({
                                      file: img,
                                      index: index,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={` col-md-6 col-12  ${
                      i18n.language === "en" ? "ps-md-5" : "pe-md-5"
                    }`}
                  >
                    <div className="">
                      {formik?.values?.audios?.map((audio: any, index: any) => {
                        return (
                          <div
                            className={
                              i18n.language === "en"
                                ? " sound position-relative "
                                : " soundArabic sound position-relative"
                            }
                          >
                            <img
                              src={x}
                              alt=""
                              width={15}
                              className=" position-absolute x_close"
                              onClick={() => {
                                setDeleteAttachmentAudio(true);
                                setCurrentAttachmentAudio({
                                  file: audio,
                                  index: index,
                                });
                              }}
                            />
                            {/* <Waveform audio={audio?.base64} /> */}
                            <audio src={audio} controls></audio>
                          </div>
                        );
                      })}
                      {formik?.values?.videos?.map((video: any, index: any) => {
                        return (
                          <div className="my-4 position-relative ">
                            {(urlParams?.ticketId
                              ? context?.ticket?.status === "CREATED" ||
                                context?.ticket?.status === "VIEWED" ||
                                context?.ticket?.status === "REASSIGNED"
                              : true) && (
                              <img
                                src={x}
                                alt=""
                                width={15}
                                className=" position-absolute x_close"
                                // onClick={() => deleteVideo(video, index)}
                                onClick={() => {
                                  setDeleteAttachmentVideo(true);
                                  setCurrentAttachmentVideo({
                                    file: video,
                                    index: index,
                                  });
                                }}
                              />
                            )}
                            <VideoPlayer
                              volume={currentAttachmentVideo?.volume}
                              url={video}
                              isPlaying={
                                currentAttachmentVideo?.index === index
                              }
                              onPlay={() => {
                                setCurrentAttachmentVideo({
                                  file: video,
                                  index: index,
                                });
                                setCurrentAttachmentSubmittedVideo({
                                  ...currentAttachmentSubmittedVideo,
                                  index: null,
                                });
                              }}
                              onPause={() => {
                                setCurrentAttachmentVideo({
                                  file: video,
                                  index: null,
                                });
                                setCurrentAttachmentSubmittedVideo({
                                  ...currentAttachmentSubmittedVideo,
                                  index: null,
                                });
                              }}
                              onVolume={(value: any) => {
                                setCurrentAttachmentVideo({
                                  file: video,
                                  index: index,
                                  volume: value,
                                });
                                setCurrentAttachmentSubmittedVideo({
                                  ...currentAttachmentSubmittedVideo,
                                  index: null,
                                });
                              }}
                              // height="360px"
                              // width="340px"
                              width="100%"
                              height="100%"
                            />
                            {/* <video width="100%" height="300" controls>
                              <source src={video} />
                              Your browser does not support HTML5 video.
                            </video> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
            {(context?.ticket?.submitted_pictures?.length > 0 ||
              context?.ticket?.submitted_videos?.length > 0 ||
              context?.ticket?.submitted_audios?.length > 0) && (
              <>
                <div
                  className={i18n.language === "en" ? "pt-5" : " pt-5 pe-md-4"}
                >
                  <label htmlFor="" className="attachline">
                    {t("new_tickets.Submitted")}
                  </label>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12 pe-md-4 ">
                    <div className=" d-flex py-5 flex-wrap gap-4">
                      {context?.ticket?.submitted_pictures.map(
                        (img: any, index: any) => {
                          return (
                            <div className="attachment" key={index}>
                              <div className="attachment position-relative">
                                <img
                                  src={img}
                                  alt=""
                                  className="embet"
                                  onClick={() => {
                                    setImageShow(true);
                                    setCurrentImage(img);
                                  }}
                                />
                                {(urlParams?.ticketId
                                  ? context?.ticket?.status === "CREATED" ||
                                    context?.ticket?.status === "VIEWED" ||
                                    context?.ticket?.status === "REASSIGNED"
                                  : true) && (
                                  <img
                                    src={x}
                                    alt=""
                                    width={15}
                                    className=" position-absolute x_close"
                                    // onClick={() => deleteImage(img, index)}
                                    onClick={() => {
                                      setDeleteAttachment(true);
                                      setCurrentAttachment({
                                        file: img,
                                        index: index,
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      i18n.language === "en"
                        ? "pt-4 col-md-6 col-12 ps-md-5"
                        : " col-md-6 col-12 pe-md-5 "
                    }
                  >
                    <div className="">
                      {context?.ticket?.submitted_audios?.map(
                        (audio: any, index: any) => {
                          return (
                            <div
                              className={
                                i18n.language === "en"
                                  ? " sound position-relative my-5"
                                  : " soundArabic sound position-relative my-5"
                              }
                            >
                              <img
                                src={x}
                                alt=""
                                width={15}
                                className=" position-absolute x_close"
                                onClick={() => {
                                  setDeleteAttachmentAudio(true);
                                  setCurrentAttachmentAudio({
                                    file: audio,
                                    index: index,
                                  });
                                }}
                              />
                              {/* <Waveform audio={audio?.base64} /> */}
                              <audio src={audio} controls></audio>
                            </div>
                          );
                        }
                      )}
                      {context?.ticket?.submitted_videos?.map(
                        (video: any, index: any) => {
                          return (
                            <div className="my-4 position-relative ">
                              {(urlParams?.ticketId
                                ? context?.ticket?.status === "CREATED" ||
                                  context?.ticket?.status === "VIEWED" ||
                                  context?.ticket?.status === "REASSIGNED"
                                : true) && (
                                <img
                                  src={x}
                                  alt=""
                                  width={15}
                                  className=" position-absolute x_close"
                                  // onClick={() => deleteVideo(video, index)}
                                  onClick={() => {
                                    setDeleteAttachmentVideo(true);
                                    setCurrentAttachmentSubmittedVideo({
                                      file: video,
                                      index: index,
                                    });
                                  }}
                                />
                              )}
                              <VideoPlayer
                                volume={currentAttachmentSubmittedVideo?.volume}
                                url={video}
                                isPlaying={
                                  currentAttachmentSubmittedVideo?.index ===
                                  index
                                }
                                onPlay={() => {
                                  setCurrentAttachmentSubmittedVideo({
                                    file: video,
                                    index: index,
                                  });
                                  setCurrentAttachmentVideo({
                                    ...currentAttachmentVideo,
                                    index: null,
                                  });
                                }}
                                onPause={() => {
                                  setCurrentAttachmentSubmittedVideo({
                                    file: video,
                                    index: null,
                                  });
                                  setCurrentAttachmentVideo({
                                    ...currentAttachmentVideo,
                                    index: null,
                                  });
                                }}
                                onVolume={(value: any) => {
                                  setCurrentAttachmentSubmittedVideo({
                                    file: video,
                                    index: index,
                                    volume: value,
                                  });
                                  setCurrentAttachmentVideo({
                                    ...currentAttachmentVideo,
                                    index: null,
                                  });
                                }}
                                height="360px"
                                width="340px"
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {(urlParams?.ticketId
              ? context?.ticket?.status === "CREATED" ||
                context?.ticket?.status === "VIEWED" ||
                context?.ticket?.status === "REASSIGNED" ||
                context?.ticket?.status === "OVERDUE"
              : true) &&
              (userContext?.roleAccess["TICKETS"].includes("UPDATE") ||
                userContext?.roleAccess["TICKETS"].includes("WRITE")) && (
                <>
                  <div className="d-flex justify-content-end py-5">
                    {urlParams?.ticketId &&
                      context?.ticket?.created_by?.id ===
                        userContext?.user?.id && (
                        <CommonButton
                          text={t("chat.Start Conversation")}
                          isLoading={isSubmitting}
                          type="submit"
                          variant="success"
                          className="me-3 text-white"
                          onClick={() => {
                            createGroupConversation();
                          }}
                        />
                      )}
                    <CommonButton
                      text={t("Reset_form")}
                      isLoading={isSubmitting}
                      type="button"
                      variant="outline-danger"
                      className="bg-transparent text-danger"
                      isDisabled={isSubmitting || !dirty}
                      onClick={() => {
                        setHeaderMessage(
                          `${t("successError.cancel the changes")}`
                        );
                        setTextMessage(`${t("successError.cancel it")}`);
                        setBodyMessage(`${t("successError.cancel it")}`);
                        setConformationPopUp(true);
                      }}
                    />
                    <CommonButton
                      text={
                        urlParams.ticketId
                          ? `${t("new_tickets.Update_Ticket")}`
                          : `${t("new_tickets.Create_Ticket")}`
                      }
                      isLoading={isSubmitting}
                      variant="success"
                      className="btn-i18n text-white"
                      isDisabled={isSubmitting || !isValid || !dirty}
                      onClick={() => {
                        setSubmitting(true);
                        setConformationPopUp(!!urlParams.ticketId);
                        setTextMessage(
                          urlParams.ticketId ? t("successError.update it") : ""
                        );
                        setHeaderMessage(
                          urlParams.ticketId
                            ? t("successError.update the ticket")
                            : ""
                        );
                        if (!urlParams.ticketId) {
                          handleUpdateData(formik.values);
                        }
                        setSubmitting(false);
                      }}
                    />
                  </div>
                </>
              )}

            {urlParams?.ticketId &&
              userContext?.roleAccess["TICKETS"].includes("VERIFY") &&
              context?.ticket?.status === "SUBMITTED" && (
                <div
                  className={`${
                    isMobileOnly
                      ? "d-flex justify-content-end py-5"
                      : "d-flex justify-content-end py-5"
                  }`}
                >
                  {userContext?.roleAccess["TICKETS"].includes("REASSIGN") && (
                    <CommonButton
                      text={t("Progressbar_status.REASSIGN")}
                      isLoading={isSubmitting}
                      type="submit"
                      variant="outline-danger"
                      className="mx-3 text-danger bg-transparent"
                      onClick={() => {
                        setSubmitting(true);
                        setConformationPopUp(true);
                        setTextMessage(t("successError.reassign it"));
                        setHeaderMessage(t("successError.reassign the ticket"));
                        setSubmitting(false);
                      }}
                    />
                  )}
                  <CommonButton
                    text={t("CreateBillBoardForm.verifyticket")}
                    isLoading={isSubmitting}
                    variant="success"
                    className=" text-white"
                    onClick={() => {
                      setSubmitting(true);
                      setConformationPopUp(true);
                      setTextMessage(t("successError.verify it"));
                      setHeaderMessage(t("successError.verify the ticket"));
                      setSubmitting(false);
                    }}
                  />
                </div>
              )}

            {urlParams?.ticketId &&
              userContext?.roleAccess["TICKETS"].includes("APPROVE/REJECT") &&
              context?.ticket?.status === "VERIFIED" && (
                <div
                  className={`${
                    isMobileOnly
                      ? "d-flex justify-content-end py-5"
                      : "d-flex justify-content-end py-5"
                  }`}
                >
                  <CommonButton
                    text={t("CreateBillBoardForm.reject")}
                    isLoading={isSubmitting}
                    variant="outline-danger"
                    className="mx-3 text-danger bg-transparent"
                    onClick={() => {
                      setSubmitting(true);
                      setConformationPopUp(true);
                      setTextMessage(t("successError.reject it"));
                      setHeaderMessage(t("successError.reject the ticket"));
                      setSubmitting(false);
                    }}
                  />
                  <CommonButton
                    text={t("CreateBillBoardForm.approvedticket")}
                    isLoading={isSubmitting}
                    variant="success"
                    className=" text-white"
                    onClick={() => {
                      setSubmitting(true);
                      setConformationPopUp(true);
                      setTextMessage(t("successError.approve it"));
                      setHeaderMessage(t("successError.approve the ticket"));
                      setSubmitting(false);
                    }}
                  />
                  {userContext?.roleAccess["TICKETS"].includes("REASSIGN") && (
                    <CommonButton
                      text={t("Progressbar_status.REASSIGN")}
                      isLoading={isSubmitting}
                      variant="outline-danger"
                      className="mx-3 text-danger bg-transparent"
                      onClick={() => {
                        setSubmitting(true);
                        setConformationPopUp(true);
                        setTextMessage(t("successError.reassign it"));
                        setHeaderMessage(t("successError.reassign the ticket"));
                        setSubmitting(false);
                      }}
                    />
                  )}
                </div>
              )}

            <Modal trigger={buttonPopup}>
              <AudioUpload
                setTrigger={setButtonPopup}
                userContext={userContext}
                uploadAudio={(data: any) => {
                  formik?.setFieldValue("audios", [
                    ...formik?.values?.audios,
                    data,
                  ]);
                }}
                setButtonPopup={setButtonPopup}
              />
            </Modal>
            <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
              <SuccessErrorToast
                bodyMessage={bodyMessage}
                isSuccess={isSuccess}
                okButton={() => setTimedPopup(false)}
                buttonName="Close"
              />
            </ToastPopup>
            <ImagePopUp
              imageShow={imageShow}
              image={currentImage}
              setImageShow={(data: any) => setImageShow(data)}
            />
            <ToastPopup trigger={loading} setTrigger={setLoading}>
              <SuccessErrorToast isLoading={true} />
            </ToastPopup>
            <ToastPopupSecond
              trigger={
                deleteAttachment
                  ? deleteAttachment
                  : deleteAttachmentVideo
                  ? deleteAttachmentVideo
                  : deleteAttachmentAudio
                  ? deleteAttachmentAudio
                  : ""
              }
              setTrigger={
                deleteAttachment
                  ? setDeleteAttachment
                  : deleteAttachmentVideo
                  ? setDeleteAttachmentVideo
                  : deleteAttachmentAudio
                  ? setDeleteAttachmentAudio
                  : ""
              }
            >
              <DeleteBlockToast
                textMessage={`${t("successError.remove it")}`}
                headerMessage={
                  deleteAttachment
                    ? `${t("successError.remove this image")}`
                    : deleteAttachmentVideo
                    ? `${t("successError.remove this video")}`
                    : deleteAttachmentAudio &&
                      `${t("successError.remove this audio")}`
                }
                submit={() =>
                  deleteAttachment
                    ? deleteImage()
                    : deleteAttachmentVideo
                    ? deleteVideo()
                    : deleteAttachmentAudio
                    ? deleteAudio()
                    : ""
                }
                closePopUp={() =>
                  deleteAttachment
                    ? setDeleteAttachment(false)
                    : deleteAttachmentVideo
                    ? setDeleteAttachmentVideo(false)
                    : deleteAttachmentAudio
                    ? setDeleteAttachmentAudio(false)
                    : false
                }
              />
            </ToastPopupSecond>
            <ToastPopupSecond
              trigger={conformationPopUp}
              setTrigger={setConformationPopUp}
            >
              <DeleteBlockToast
                closePopUp={(data: any) => setConformationPopUp(data)}
                submit={() => {
                  setConformationPopUp(false);
                  setImageShow(false);
                  headerMessage === t("successError.verify the ticket")
                    ? approveTicket(true)
                    : headerMessage === t("successError.approve the ticket")
                    ? approveTicket(false)
                    : headerMessage === t("successError.reject the ticket")
                    ? rejectTicket()
                    : headerMessage === t("successError.reassign the ticket")
                    ? reAssignTicket()
                    : headerMessage === t("successError.cancel the changes")
                    ? cancelForm()
                    : handleUpdateData(formik.values);
                }}
                headerMessage={headerMessage}
                textMessage={textMessage}
              />
            </ToastPopupSecond>
          </Form>
        </FormikProvider>
      )}
    </div>
  );
};
export default CreateNewTicketForm;
