import {
  ErrorMessage,
  Field,
  FieldProps,
  FormikProvider,
  useFormik,
} from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import DatePicker from "react-multi-date-picker";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CommonButton from "../../../components/CommonButton/CommonButton";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import ReactCustomSelect from "../../../components/CommonComponents/ReactCustomSelect";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import { routes } from "../../../constants/routes";
import { useMasters } from "../../../context/MasterProider";
import AppLayout from "../../../layouts/AppLayout";
import FormikField from "../../../components/FormikField/FormikField";
import CustomInputField from "../../../components/InputField/CustomInputField";
import FormikDateField from "../../../components/FormikDateField/FormikDateField";

const CreateNewMaterial = ({ userContext }: any) => {
  const context = useMasters();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const urlParams = useParams();
  const pathName = window?.location?.pathname;

  return (
    <>
      <AppLayout>
        <div
          className="edit_members p-3 "
          style={
            isMobile
              ? { background: "#FAFAFA", height: "auto" }
              : { background: "#FAFAFA", minHeight: "80vh" }
          }
        >
          <div className="members py-4 mt-2 ">
            <h5
              className={`${
                isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
              onClick={() => navigate(routes.mastersMaterial)}
            >
              {t("inventory.All Materials")}
            </h5>
            <>
              <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                <MdOutlineArrowForwardIos />
              </span>
              <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                <MdArrowBackIos />
              </span>

              <h5
                className={`${
                  isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "
                }`}
              >
                {urlParams?.materialId && pathName?.includes("/edit")
                  ? `${t("inventory.Edit Stock")}`
                  : pathName?.includes("/view")
                  ? `${t("inventory.View Stock")}`
                  : `${t("inventory.New Material")}`}
              </h5>
            </>
          </div>

          {context && (
            <NewMaterialForm
              context={context}
              urlParams={urlParams}
              pathName={pathName}
            />
          )}
        </div>
      </AppLayout>
    </>
  );
};

interface NewMaterialFormProps {
  context: any;
  urlParams: any;
  pathName: string;
}

interface SupplierAutocompleteProps {
  readonly label: string;
  readonly value: string;
}

const NewMaterialForm = ({
  context,
  urlParams,
  pathName,
}: NewMaterialFormProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [supplierList, setSupplierList] = useState<any>([]);

  useEffect(() => {
    if (!urlParams?.materialId) {
      context?.setStock(null);
    }
    getSupplierDetails();
  }, []);

  useEffect(() => {
    editMaterial();
  }, [urlParams?.materialId]);

  const editMaterial = async () => {
    await context?.getMaterialDetail(urlParams?.materialId);
  };

  const getSupplierDetails = async () => {
    const { success, suppliers } = await context?.loadSupplier({
      status: "ACTIVE",
    });
    if (success) {
      let res = suppliers.map((d: any) => {
        return {
          label: d.supplier_id,
          value: d.id,
          name: d.name,
        };
      });
      setSupplierList(res);
    }
  };

  const getSupplierOptions: any = async (
    inputValue: string,
    callback: (options: SupplierAutocompleteProps) => void
  ) => {
    const { suppliers } = await context?.loadSupplier({
      status: "ACTIVE",
      search: inputValue,
    });
    callback(
      suppliers.map((d: any) => ({
        label: d?.supplier_id,
        value: d?.id,
        name: d?.name,
      }))
    );
  };

  const initialValues = {
    supplier_id: context?.stock?.supplier_id?.id || "",
    supplier_name: context?.stock?.supplier_id?.supplier_id || "",
    supplier_part_no: context?.stock?.supplier_part_no || "",
    material_name: context?.stock?.material_name || "",
    stock_code: context?.stock?.stock_code || "",
    part_description: context?.stock?.part_description || "",
    available_qty_in_store: context?.stock?.available_qty_in_store || "",
    qty_used_on_site: context?.stock?.qty_used_on_site || "",
    minimum_quantity: context?.stock?.minimum_quantity || "",
    maximum_quantity: context?.stock?.maximum_quantity || "",
    lead_time_to_order: context?.stock?.lead_time_to_order || "",
    warranty_period: context?.stock?.warranty_period || "",
    project_name: context?.stock?.project_name || "",
  };

  const validationSchema = Yup.object({
    supplier_id: Yup.string(),
    id: Yup.string(),
    supplier_part_no: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Supplier part no is required"),
    material_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Material Name is required"),
    stock_code: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Stock code is required"),
    part_description: Yup.string()
      .min(2, "Too Short!")
      .max(1000)
      .required("Description is required"),
    available_qty_in_store: Yup.string(),
    qty_used_on_site: Yup.string(),
    minimum_quantity: Yup.string().required("Minimum quantity  is required"),
    maximum_quantity: Yup.string().required("Maximum quantity  is required"),
    lead_time_to_order: Yup.string().required("Lead time to order is required"),
    warranty_period: Yup.string().required("Warranty period is required"),
    project_name: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const params = {
        ...values,
        available_qty_in_store: parseInt(values?.available_qty_in_store),
        qty_used_on_site: parseInt(values?.qty_used_on_site),
        minimum_quantity: parseInt(values?.minimum_quantity),
        maximum_quantity: parseInt(values?.maximum_quantity),
      };
      const { success, errors } = context?.stock?.id
        ? await context.updateStockDetail(context?.stock?.id, params)
        : await context.addMaterials(params);
      setIsSuccess(success);
      setOpenPopup(true);
      if (success) {
        setBodyMessage(
          context?.stock?.id
            ? `${t("inventory.Stock updated sucessfully")}`
            : `${t("inventory.Stock Added sucessfully")}`
        );
        formik.resetForm();
        timeOutPopUp(true);
      } else {
        setBodyMessage(errors);
        timeOutPopUp(false);
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.mastersMaterial);
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const leadTimeToOrder = [
    { value: "Day", label: `${t("dashboard.Day")}` },
    { value: "Week", label: `${t("dashboard.Week")}` },
    { value: "Month", label: `${t("dashboard.Month")}` },
    { value: "Year", label: `${t("dashboard.Year")}` },
  ];

  const {
    errors,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    resetForm,
    setFieldValue,
    values,
  } = formik;
  return (
    <>
      <div className="px-4 pb-5">
        <FormikProvider value={formik}>
          <Form noValidate={true} onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="stock_code"
                  type="text"
                  autoFocus
                  validationSchema={validationSchema}
                  label={t("inventory.Stock Code")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>

              <Col md={6} className="formprofile mb-4">
                <Field name="supplier_id">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("inventory.Supplier ID")}
                        error={errors["supplier_id"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isMulti={false}
                          values={{
                            label: formik?.values?.supplier_name,
                            value: formik.values.supplier_id,
                          }}
                          options={supplierList}
                          cacheOptions
                          loadOptionValue={getSupplierOptions}
                          updateField={(option: any) => {
                            formik?.setFieldValue("supplier_id", option.value);
                            formik?.setFieldValue(
                              "supplier_name",
                              option.label
                            );
                          }}
                          isFieldDisabled={pathName?.includes("/view")}
                          isShow={true}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="material_name"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("inventory.Material Name")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>
              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="supplier_part_no"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("inventory.Supplier No")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>

              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="available_qty_in_store"
                  type="string"
                  validationSchema={validationSchema}
                  label={t("inventory.Available quantity in store")}
                  errors={errors as Record<string, string>}
                  maxLength={20}
                  onChange={(e: any) => {
                    if (
                      /^[0-9\b]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setFieldValue("available_qty_in_store", e?.target?.value);
                    }
                  }}
                  disabled={context?.stock?.id ? true : false}
                />
              </Col>

              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="qty_used_on_site"
                  type="string"
                  validationSchema={validationSchema}
                  label={t("inventory.Quantity used on site")}
                  errors={errors as Record<string, string>}
                  maxLength={20}
                  onChange={(e: any) => {
                    if (
                      /^[0-9\b]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setFieldValue("qty_used_on_site", e?.target?.value);
                    }
                  }}
                  disabled={context?.stock?.id ? true : false}
                />
              </Col>

              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="minimum_quantity"
                  type="string"
                  maxLength={20}
                  validationSchema={validationSchema}
                  label={t("inventory.Minimum quantity")}
                  errors={errors as Record<string, string>}
                  onChange={(e: any) => {
                    if (
                      /^[0-9\b]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setFieldValue("minimum_quantity", e?.target?.value);
                    }
                  }}
                  disabled={pathName?.includes("/view")}
                />
              </Col>

              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="maximum_quantity"
                  type="string"
                  maxLength={20}
                  validationSchema={validationSchema}
                  label={t("inventory.Maximum quantity")}
                  errors={errors as Record<string, string>}
                  onChange={(e: any) => {
                    if (
                      /^[0-9\b]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setFieldValue("maximum_quantity", e?.target?.value);
                    }
                  }}
                  disabled={pathName?.includes("/view")}
                />
              </Col>

              <Col md={6} className="formprofile mb-4">
                <Field name="lead_time_to_order">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("inventory.Lead time to order")}
                        error={errors["lead_time_to_order"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <ReactCustomSelect
                          feildName={"lead_time_to_order"}
                          className={"select-wrapper"}
                          defaultOptions={leadTimeToOrder?.find(
                            (item) => values?.lead_time_to_order === item?.value
                          )}
                          isMulti={false}
                          updateFieldValue={(data: any) => {
                            setFieldValue("lead_time_to_order", data?.value);
                          }}
                          dataOptions={leadTimeToOrder}
                          searchDisabled={false}
                          disabled={pathName?.includes("/view")}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={6} className="formprofile">
                <FormikDateField
                  name={"warranty_period"}
                  label={` ${t("inventory.Warranty period")} *`}
                  isDisabled={pathName?.includes("/view")}
                />
              </Col>

              <Col className=" mb-4  formprofile">
                <FormikField
                  name="part_description"
                  type="text"
                  as="textarea"
                  validationSchema={validationSchema}
                  label={t("inventory.Description")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>
              <Col className=" mb-4  formprofile">
                <FormikField
                  name="project_name"
                  type="text"
                  as="textarea"
                  maxLength={20}
                  disabled={pathName?.includes("/view")}
                  errors={errors as Record<string, string>}
                  validationSchema={validationSchema}
                  label={t("inventory.Project Name")}
                />
              </Col>
            </Row>

            {!pathName?.includes("/view") && (
              <div className="d-flex justify-content-end py-5">
                <CommonButton
                  text={t("inventory.Cancel")}
                  isLoading={isSubmitting}
                  variant="outline-danger"
                  className="bg-transparent text-danger"
                  isDisabled={isSubmitting || !dirty}
                  onClick={() => resetForm()}
                />

                <CommonButton
                  text={
                    context?.stock?.id
                      ? `${t("inventory.Update Stock")}`
                      : `${t("inventory.Add Stock")}`
                  }
                  isLoading={isSubmitting}
                  type="submit"
                  variant="success"
                  className="btn-i18n text-white"
                  isDisabled={isSubmitting || !isValid || !dirty}
                />
              </div>
            )}
          </Form>
        </FormikProvider>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
        <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            isSuccess={isSuccess}
            footButton={false}
          />
        </ToastPopup>
      </div>
    </>
  );
};

export default CreateNewMaterial;
